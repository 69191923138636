import React from "react"
import Layout from "../layout/layout"
import Wrapper from "../components/wrapper"

const AboutPage = () => (
  <Layout title="About" description="MERN Stack Developer">
    <section id="#short">
      <Wrapper>
        <h1>ABOUT ME</h1>
      </Wrapper>
    </section>
    <section>
      <h2>Skills</h2>
      <div className="icons">
        <h3>Languages</h3>
        <ul>
          <li>javascript</li>
          <li>python</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Frontend</h3>
        <ul>
          <li>html5</li>
          <li>css3</li>
          <li>sass</li>
          <li>bootstrap4</li>
          <li>react</li>
          <li>redux</li>
          <li>gatsby</li>
          <li>webpack</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Backend</h3>
        <ul>
          <li>node</li>
          <li>express</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Databases</h3>
        <ul>
          <li>mongodb</li>
          <li>mysql</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Documentation</h3>
        <ul>
          <li>swagger</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Testing</h3>
        <ul>
          <li>jest</li>
          <li>supertest</li>
          <li>enzyme</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Payments</h3>
        <ul>
          <li>stripe</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Cloud</h3>
        <ul>
          <li>firebase</li>
        </ul>
      </div>
      <div className="icons">
        <h3>Others</h3>
        <ul>
          <li>git</li>
          <li>vps configuration</li>
          <li>scrum</li>
        </ul>
      </div>
    </section>
  </Layout>
)

export default AboutPage
